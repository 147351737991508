import React from 'react'
import PropTypes from 'prop-types';

const ContainerTypes = {
    default: 'container--default',
    static: 'container--static',
    container_2: 'container_2',
    container_3: 'container_3',
    no_container: '',
}

const Container = ({ className, type, justify, children, ...rest }) => {
    return (
        <div container={type} className={`${ContainerTypes[type] || ContainerTypes.default} ${className}`} {...rest}>{children}</div>
    )
}
Container.defaultProps = {
    type: 'default',
    className: '',
    justify: 'start'
}

Container.propTypes = {
    /**
     * Class name
     */
    className: PropTypes.string,
    /**
     * Container content
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string
    ]).isRequired,
    /**
     * Container type
     */
    type: PropTypes.oneOf(['default', 'static', 'fullWidth', 'container_2', 'container_3']),
}

export default Container;
