import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


import Card from './Card';
import Portal from './Portal';
import SvgIcon from './SvgIcon'
import IconClose from './SvgIcons/IconClose.svg';

const modalTypes = {
    modalTypeZero: 'modalTypeZero',
    withoutCloseIcon1: 'withoutCloseIcon2',
    withoutCloseIcon2: 'withoutCloseIcon2',
    withoutCloseIcon3: 'withoutCloseIcon3',
    withoutCloseIcon4: 'withoutCloseIcon4',
    ghost: '__'
}

const Modal = ({ className, containerClass, closeClass = '', type = 'withoutCloseIcon1', children, isModalOpen, handlerModalClose, isShowCloseBtn, containerPadding, containerAlignment, dataAutomation, rounded = 'rounded-2xl-0.5', bgColor='bg-gray-300', backGround = 'bg-basic-white', boxShadow='shadow-sm' }) => {
    
    useEffect(() => {
        if (isModalOpen) {
            window?.disableBot?.()
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
            window?.enableBot?.()
        }

        const keyUpEvent = (event) => {
            if (event?.code === 'Escape') {
                (handlerModalClose && isModalOpen) && handlerModalClose({ event })
            }
        }

        window.addEventListener('keyup' , keyUpEvent)

        return () => {
            document.body.style.overflow = 'auto';
            window.removeEventListener('keyup', keyUpEvent);
        };
    }, [isModalOpen])

    return (
        <Card className={`${containerClass} ${isModalOpen ? 'visible' : 'hidden'} fixed left-0 top-0 w-full h-screen z-100`} data-modal={dataAutomation} keyUp={(e) => {console.log(e)}}>
            <div className={`absolute w-full h-full ${bgColor} opacity-60 blur-10 z-9 `}></div>
            <div className="fixed left-0 top-0 w-full h-full overflow-y-auto z-10 customScrollbar">
                <div className={`${containerPadding ? containerPadding : 'px-5'} ${containerAlignment} flex  min-h-full`} {...(handlerModalClose && { onClick: (event) => { event.bubbles = false; handlerModalClose({ event }); } })} {...(dataAutomation && { 'data-automation': dataAutomation + '-modal-close' })}>
                    <div id='modal' className={`${className} ${modalTypes[type] || modalTypes.withoutCloseIcon1} ${rounded} my-7.5 mx-auto ${backGround} ${boxShadow} relative z-30 overflow-hidden`} onClick={(e) => e.stopPropagation()}>
                        {isShowCloseBtn && <button title='Press Escape to close' className='absolute top-3 right-3 z-100'><IconClose className={`${closeClass} w-4 md:w-5 stroke-gray-900 hover:stroke-primary1-500`} {...(handlerModalClose && { onClick: (event) => handlerModalClose({ event }) })} {...(dataAutomation && { 'data-automation': dataAutomation + '-modal-close' })} /></button>}
                        {children}
                    </div>
                </div>
            </div>
        </Card>
    )
}

Modal.defaultProps = {
    containerClass: '',
    className: '',
    containerPadding: '',
    containerAlignment: 'items-center',
}

Modal.propTypes = {
    containerClass: PropTypes.string,
    containerPadding: PropTypes.string,
    className: PropTypes.string,
    isModalOpen: PropTypes.bool.isRequired,
    isShowCloseBtn: PropTypes.bool,
    handlerModalClose: PropTypes.func,
    containerAlignment: PropTypes.string,
}

export default Portal(Modal)
