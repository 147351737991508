import * as HttpClient from './http.service';
import { getAuth } from './identity.service';
import { GET_ALL_BLOGS_LISTING_VIA_ELASTIC, GET_ALL_BLOGS_LISTING_VIA_ELASTIC_WITH_PUB, GET_ALL_BLOG_CATEGORIES, GET_ALL_BLOG_CATEGORIES_WITH_PUB, GET_BLOG_BY_SLUG, GET_BLOG_BY_SLUG_WITH_PUB, GET_SIMILAR_DOCTOR_CLINIC, UPDATE_BLOG_LIKE_SHARE_COUNT, UPDATE_BLOG_LIKE_SHARE_COUNT_WITH_PUB } from './url.service';

let auth = getAuth()
const formUrlWithQuery = (url, queries) => {
    const queryString = Object
        .keys(queries)
        .filter(item => queries[item] !== undefined)
        .map(item => `${item}=${queries[item]}`)
        .join('&');

    return `${url}${queryString.length ? `?${queryString}` : ''}`
}

export const getBlogsList = ({ page, search, language, categories, sortby, like, featured, mediaType }) => {
    if (auth) {
        return HttpClient.getWithAuth(formUrlWithQuery(GET_ALL_BLOGS_LISTING_VIA_ELASTIC(), { page, search, language, categories, sortby, like, featured, mediaType }));
    }
    return HttpClient.getWithOutAuth(formUrlWithQuery(GET_ALL_BLOGS_LISTING_VIA_ELASTIC_WITH_PUB(), { page, search, language, categories, sortby, like, featured, mediaType }));
};

export const getBlogBySlug = async(slug, token) => {
    if (token) {
        let response =await HttpClient.getWithToken(GET_BLOG_BY_SLUG(slug), token);
        return response.data
    }
    return HttpClient.getWithOutAuth(GET_BLOG_BY_SLUG_WITH_PUB(slug));
};

export const getBlogCategories = async({ language, search, blogType }) => {
    if (auth) {
        const response = await HttpClient.getWithToken(formUrlWithQuery(GET_ALL_BLOG_CATEGORIES(), { language, search, blogType }), auth?.token);
        return response.data
    }
    return HttpClient.getWithOutAuth(formUrlWithQuery(GET_ALL_BLOG_CATEGORIES_WITH_PUB(), { language, search, blogType }));
};

export const getSimilarDoctorClinic = (id, limit) => {
    return HttpClient.getWithOutAuth(formUrlWithQuery(GET_SIMILAR_DOCTOR_CLINIC(id), { limit }));
};

export const updateBlogLikeShareCount = (id, action) => {
    if (auth) {
        return HttpClient.putWithAuth(UPDATE_BLOG_LIKE_SHARE_COUNT(id, action));
    }
    return HttpClient.putWithOutAuth(UPDATE_BLOG_LIKE_SHARE_COUNT_WITH_PUB(id, action));
};
