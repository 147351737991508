import { Svg } from "atoms/SvgIcon";

const IconLogout = ({ ...rest }) => {
    return <Svg viewBox='0 0 26 22' type='IconLogout' {...rest} >
        <path d="M13.016 5.38951V4.45651C13.016 2.42151 11.366 0.771515 9.33097 0.771515H4.45597C2.42197 0.771515 0.771973 2.42151 0.771973 4.45651V15.5865C0.771973 17.6215 2.42197 19.2715 4.45597 19.2715H9.34097C11.37 19.2715 13.016 17.6265 13.016 15.5975V14.6545" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" />
        <path d="M19.8095 10.0214H7.76849" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" />
        <path d="M16.8812 7.10632L19.8092 10.0213L16.8812 12.9373" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" />
    </Svg>
};

export default IconLogout;