import { deafultImagePath } from "constant/medicine/default-medicine-parameters";
import { getTransformedPackagesPrices } from "../medicine-detail/get-transformed-packages-prices";

export const getTransformedProductsData = (productsList) => {
  const transformedProductsList = [];
  const bucketUrl = process.env.NEXT_PUBLIC_BUCKET_URL_CATEGORY.replace('images/package/', '');
  productsList?.map((info) => {
    let medicineListObj = {
      id: info?.packageId,
      brandId: info?.brandId,
      slug: info?.slug,
      brand_name: info?.brandName,
      preview_img: info?.image ? `${bucketUrl}${info?.image}` : deafultImagePath,
      name: info?.medicineName,
      is_deal: info?.isDeal,
      bookmark_medicine: info?.isBookmarked,
      applicableCouponCode: info?.applicableCouponCode ? info?.applicableCouponCode : null,
      campaignName: info.campaignName,
      campaignStartDate: info.campaignStartDate,
      campaignEndDate: info.campaignEndDate,
      inStock: info?.inStock,
      packagingName: info?.packagingName,
      packageId: info?.packageId,
      packagingId: info?.packagingId,
      priceMarginId: info?.priceMarginId,
      discountPercentage: info?.discountPercentage,
      mrp: info?.mrp,
      discountedMrp: info?.discountedMrp,
      maxMrp: info?.maxMrp,
      discountedMaxMrp: info?.discountedMaxMrp,
      showPackaging: info?.showPackaging,
      discountedMaxMrp: info?.discountedMaxMrp,
      discountedMrpDifference: info?.discountedMrpDifference,
      brandImage: info?.brandImage ? `${bucketUrl}${info?.brandImage}` : deafultImagePath,
      brandName: info?.brandName,
      isIncludedInDeal:info?.isIncludedInDeal,
      originalDiscountPercentage: info?.originalDiscountPercentage,
      originalDiscountMrp: info?.originalDiscountMrp,
      isOTC:info.isOTC
    };
    transformedProductsList.push(medicineListObj);
  });
  return transformedProductsList;
};
