import React from 'react'
import { getAuth } from '../services/identity.service';
import { useRouter } from 'next/router'
import Marquee from 'atoms/Marquee';
import LinkAnchor from 'atoms/LinkAnchor';
import CouponMarquee from './CouponMarquee';
const VerifiedMarquee = ({ massege }) => {
    const router = useRouter();
    const auth = getAuth();
    const verifyStiky = auth?.user?.tbl_doctor_profile?.is_verified
    const checkPath = router.pathname;
    const couponCode = auth?.user?.couponCode
    const couponOff = auth?.user?.couponOff

    return (
        <div>
            {auth &&
                massege == true && verifyStiky != 1
                ? ['/medicines', "/", '/feeds', '/profile'].includes(checkPath) &&
                <Marquee>
                    {massege} <LinkAnchor href='/profile-verification' className='ml-1 text-primary1-500'>click here</LinkAnchor>
                </Marquee>
                : (null)}
            {massege == true && verifyStiky == 0 ? <CouponMarquee offer={couponOff} couponName={couponCode} /> : null}
        </div>
    )
}

export default VerifiedMarquee