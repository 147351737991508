import React from 'react';

import PropTypes from "prop-types";
import SvgIcon from '../atoms/SvgIcon';
import ImageNext from '../atoms/ImageNext';
import Text from '../atoms/Text';
import { DIRECTION, DIRECTION_MD } from 'styles/theme';

const TextIcon = ({
    className, type, children, minLength, color, fontWeight, iconType, iconClass, dataAutomation, iconSize,
    direction, directionMd, iconImageWidth, iconImageHeight, iconImageSrc, tag, display, icon, ...rest
}) => {
    return <Text className={`${(iconType || iconImageSrc) ? `${display} items-center` : ''} ${className} ${DIRECTION[direction] || ''} ${DIRECTION_MD[directionMd] || ''} `} type={type} color={color} fontWeight={fontWeight} data-automation={dataAutomation} {...rest}>
        {icon}
        {iconImageSrc && <ImageNext className={`${iconClass} shrink-0`} src={iconImageSrc} alt='icon' width={iconImageWidth} height={iconImageHeight} />}
        {children && <span> {children} </span>}
    </Text>
}

TextIcon.defaultProps = {
    className: '',
    color: '',
    fontWeight: '',
    iconClass: '',
    display: 'inline-flex',
}


TextIcon.propTypes = {
    className: PropTypes.string,
    /**
     * Which type should the button be?
     */
    type: PropTypes.oneOf(['bodySmall', 'body', 'bodyLarge', 'label', 'subtitle', 'caption', 'captionSmall', 'paragraph', 'bold_v2', 'overline']),
    direction: PropTypes.oneOf(['LEFT', 'RIGHT', 'TOP', 'BOTTOM']),
    /**
     * It's apply on after 768px break-point
     */
    directionMd: PropTypes.oneOf(['LEFT', 'RIGHT', 'TOP', 'BOTTOM']),
    iconSize: PropTypes.number,
    color: PropTypes.string,
    fontWeight: PropTypes.number,
    iconClass: PropTypes.string,
    display: PropTypes.string,
}

export default TextIcon;