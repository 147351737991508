import React from 'react'
import PropTypes from 'prop-types';

const PATTERN = {
    WITHOUT_LABEL: '',
    RING: 'ring-',
    WITH_LABEL: 'with-label-'
}

const USER_CATEGORY_NAMES = {
    'IRON': '',
    'SILVER': '',
    'GOLD': '',
    'DIAMOND': '',
    'PLATINUM': '',
}

const UserCategoryBadge = (props) => {
    const { className, type, userCategory, ...rest } = props;
    return (
        (userCategory && userCategory in USER_CATEGORY_NAMES)
            ? <div className={`${className} ${type === 'RING' ? ' h-full pointer-events-none' : ''}`}  {...rest}>
                <img src={`/images/${PATTERN[type]}${userCategory.toLowerCase()}.svg`} />
            </div>
            : null
    )
}

export default UserCategoryBadge


UserCategoryBadge.defaultProps = {
    className: '',
    type: 'WITHOUT_LABEL'
}

UserCategoryBadge.propTypes = {
    className: PropTypes.string,
    userCategory: PropTypes.oneOf(['WEB', 'IRON', 'SILVER', 'GOLD', 'DIAMOND', 'PLATINUM']),
    type: PropTypes.oneOf(['WITH_LABEL', 'WITHOUT_LABEL', 'RING'])
}