import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom';

const Portal = (Component) => (props) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);

        return () => setMounted(false);
    }, []);

    return mounted
        ? createPortal(
            <Component {...props} />,
            document.querySelector('#__next') || document.body)
        : null;
}

export default Portal
