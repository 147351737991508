import { trackEvent } from "services/moengage.service";
import blog_moengage from "../../assets/mo_engage/blogs.json";

const events = blog_moengage.entity;

export const moEngageBlogQuickLink = (attributes = {}) => {
    trackEvent(events.Blog_Quick_link.event_name, attributes);
};

export const moEngageSearchBlog = ({ search_text_value }) => {
    const { KEY_SEARCH_STRING } = events.Search_Blog.attributes_search_blog;

    const attributes = {
        [KEY_SEARCH_STRING]: search_text_value,
    }
    trackEvent(events.Search_Blog.event_name, attributes);
};

export const moEngageBlogScrollTime = ({ scroll_time_value }) => {
    const { scroll_time_key } = events.Blog_scroll_time.attributes_blog_scroll_time;
    const attributes = {
        [scroll_time_key]: scroll_time_value,
    }
    trackEvent(events.Blog_scroll_time.event_name, attributes);
};

export const moEngageBlogSortTypeSelect = (attributes = {}) => {
    trackEvent(events.Blog_Sort_Type_Select.event_name, attributes);
}

export const moEngageBlogLanguageTypeSelect = (attributes = {}) => {
    trackEvent(events.Blog_Language_Type_Select.event_name, attributes);
}

export const moEngageBlogCategoryClick = (attributes = {}) => {
    trackEvent(events.Blog_Category_Click.event_name, attributes);
}

export const moEngageFeaturedClick = (attributes = {}) => { 
    trackEvent(events.Blog_Featured_Click.event_name, attributes);
}

export const moEngageFeaturedCarouselClick = (attributes = {}) => { 
    trackEvent(events.Blog_Featured_Carousel_Click.event_name, attributes);
}

export const moEngageBlogCardAuthorClick = (attributes = {}) => { 
    trackEvent(events.Blog_Card_Author_Click.event_name, attributes);
}

export const moEngageBlogCardLikeIcon = (attributes = {}) => { 
    trackEvent(events.Blog_Card_like_icon.event_name, attributes);
}

export const moEngageBlogCardTagClick = (attributes = {}) => {
    trackEvent(events.Blog_Card_Tag_Click.event_name, attributes);
}

export const moEngageBlogCardBookAppointmentClick = (attributes = {}) => {
    trackEvent(events.Blog_Card_Book_Appointment_Click.event_name, attributes);
}

export const moEngageBlogCardViewClick = (attributes = {}) => {
    trackEvent(events.Blog_Card_View_Click.event_name, attributes);
}

export const moEngageBlogCardTitleClick = (attributes = {}) => {
    trackEvent(events.Blog_Card_Title_Click.event_name, attributes); 
}

export const moEngageBlogDetailAuthorClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Author_Click.event_name, attributes);
}

export const moEngageBlogDetailLikeIcon = (attributes = {}) => {
    trackEvent(events.Blog_Detail_like_icon.event_name, attributes);
}

export const moEngageBlogDetailTagClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Tag_Click.event_name, attributes);
}

export const moEngageBlogDetailBookAppointmentClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Book_Appointment_Click.event_name, attributes);
}

export const moEngageBlogDetailSimilarDoctorSeeAllClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Similar_Doctor_See_All_Click.event_name, attributes);
}

export const moEngageBlogDetailSimilarDoctorNameClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Similar_Doctor_Name_Click.event_name, attributes);
}

export const moEngageBlogDetailSimilarClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Similar_Click.event_name, attributes);
}

export const moEngageBlogDetailSimilarCarouselClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Similar_Carousel_Click.event_name, attributes);
}

export const moEngageBlogDetailFacebookClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Facebook_Click.event_name, attributes);
}

export const moEngageBlogDetailTwitterClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Twitter_Click.event_name, attributes);
}

export const moEngageBlogDetailLinkedinClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Linkedin_Click.event_name, attributes);
}

export const moEngageBlogDetailShareClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Share_Click.event_name, attributes);
}

export const moEngageBlogRelatedCardAuthorClick = (attributes = {}) => {
    trackEvent(events.Blog_Related_Card_Author_Click.event_name, attributes);
}

export const moEngageBlogRelatedCardLikeIcon = (attributes = {}) => {
    trackEvent(events.Blog_Related_Card_like_icon.event_name, attributes);
}

export const moEngageBlogRelatedCardTagClick = (attributes = {}) => {
    trackEvent(events.Blog_Related_Card_Tag_Click.event_name, attributes);
}

export const moEngageBlogRelatedCardTitleClick = (attributes = {}) => {
    trackEvent(events.Blog_Related_Card_Title_Click.event_name, attributes);
}

export const moEngageBlogRelatedCardViewClick = ({ BLOG_NAME }) => {
    const { KEY_BLOG_NAME } = events.Blog_Related_Card_View_Click.attributes;
    const attributes = {
        [KEY_BLOG_NAME]: BLOG_NAME,
    }
    trackEvent(events.Blog_Related_Card_View_Click.event_name, attributes);
}

export const moEngageBlogRelatedCarouselClick = ({ POSITION }) => {
    const { KEY_POSITION } = events.Blog_Related_Carousel_Click.attributes;
    const attributes = {
        [KEY_POSITION]: POSITION,
    }
    trackEvent(events.Blog_Related_Carousel_Click.event_name, attributes);
};
export const moEngageBlogDetailMedicineNameClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Medicine_Name_Click.event_name, attributes);
}

export const moEngageBlogDetailAddToCartClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Add_To_Cart_Click.event_name, attributes);
}

export const moEngageBlogDetailSaveMedicineClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Save_Medicine_Click.event_name, attributes);
}

export const moEngageBlogDetailShareMedicineClick = (attributes = {}) => {
    trackEvent(events.Blog_Detail_Share_Medicine_Click.event_name, attributes);
}

export const moEngageBlogMedicineNameClick = (attributes = {}) => {
    trackEvent(events.Blog_Medicine_Name_Click.event_name, attributes);
}

export const moEngageBlogAddToCartClick = (attributes = {}) => {
    trackEvent(events.Blog_Add_To_Cart_Click.event_name, attributes);
}

export const moEngageBlogSaveMedicineClick = (attributes = {}) => {
    trackEvent(events.Blog_Save_Medicine_Click.event_name, attributes);
}

export const moEngageBlogShareMedicineClick = (attributes = {}) => {
    trackEvent(events.Blog_Share_Medicine_Click.event_name, attributes);
}

export const moEngageBlogMediaType = (url)=>{
    let event = ""
    if(url == "/blogs"){
        event= events.Blog_Media_Header_Click.event_name
    }else if(url == "/news"){
        event= events.Blog_Media_News_Header_Click.event_name
    }else if(url == "/photos"){
        event= events.Blog_Media_Photos_Header_Click.event_name
    }else if(url == "/videos"){
        event= events.Blog_Media_Videos_Header_Click.event_name
    }else{
        event= events.Blog_Media_Shorts_Header_Click.event_name
    }
    trackEvent(event, {});
}