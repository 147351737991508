import React from 'react'

const Marquee = (props) => {
    const { className = '', children } = props;
    return (
        <div className={`${className} marquee flex items-center h-11 w-full overflow-hidden relative bg-other-yellow-400 text-2xl font-semibold text-gray-900`}>
            <div className="marquee__inner w-fit flex relative" aria-hidden="true">
                {Array.from({ length: 4 }).map((_, index) => <span className='whitespace-nowrap flex' key={index}>{children}</span>)}
            </div>
        </div>
    )
}

export default Marquee