import React from 'react'
import Marquee from 'atoms/Marquee';
import LinkAnchor from 'atoms/LinkAnchor';
import IconAngleRight from 'atoms/SvgIcons/IconAngleRight.svg';
const CouponMarquee = ({ offer, couponName }) => {

    return (
        <div>
            <Marquee>
                Verify your profile & use coupon
                <span className='text-other-red-500 font-bold uppercase' style={{ padding: '0px 4px 0px 4px' }}>{couponName}</span>
                to get
                <b className='pl-1 pr-1'>{offer} OFF*</b> on 1<sup className='top-[9px] pr-1'>st</sup> order
                <LinkAnchor href='/profile-verification' className='ml-1 text-primary1-500'>Verify Now <IconAngleRight className='h-5 inline-block stroke-primary1-500' /></LinkAnchor>
            </Marquee>
        </div>
    )
}

export default CouponMarquee